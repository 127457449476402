import GoogleMapOptionsContract from '@/contracts/GoogleMapOptionsContract';

export default class GoogleMapService {
    public options: Object | GoogleMapOptionsContract = {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true,
        disableDefaultUi: false,
        styles: [
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [
                    { visibility: 'off' },
                ],
            },
            {
                featureType: 'transit',
                elementType: 'labels',
                stylers: [
                    { visibility: 'off' },
                ],
            },
        ],
        gestureHandling: 'auto',
    };

    public zoom: number = 16;
}
